import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['row']
    static values = {
        href: String
    }

    // onClick navigate through turbo if the target is not a link or a button
    onClick(event) {
        if (this.canNavigate(event.target)) {
            Turbo.visit(this.hrefValue, {})
        }
    }

    canNavigate(el) {
        const blacklistedTagNames = ['A', 'BUTTON', 'INPUT']
        if (blacklistedTagNames.includes(el.tagName)) {
            return false
        }

        return el.parentElement.tagName !== 'A';
    }
}