import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]

    connect() {
        this.minValue = this.inputTarget.getAttribute('min')
        this.maxValue = this.inputTarget.getAttribute('max')


        this.inputTarget.addEventListener('change', this.changeDispatcher.bind(this))
        this.inputTarget.addEventListener('input', this.changeDispatcher.bind(this))
    }

    disconnect() {
        this.inputTarget.removeEventListener('change', this.changeDispatcher)
        this.inputTarget.removeEventListener('input', this.changeDispatcher)
    }

    decrease() {
        let currentValue = parseInt(this.inputTarget.value, 10) ?? 2
        if (isNaN(currentValue) || currentValue === undefined || currentValue === null) {
            this.setValue(1)
            return
        }


        if (currentValue - 1 >= this.minValue) {
            this.setValue(currentValue - 1)
        }
    }

    increase() {
        let currentValue = parseInt(this.inputTarget.value, 10)
        if (isNaN(currentValue) || currentValue === undefined || currentValue === null) {
            this.setValue(1)
            return
        }


        if (currentValue + 1 <= this.maxValue) {
            this.setValue(currentValue + 1)
        }
    }

    setValue(value) {
        this.inputTarget.value = value
        this.inputTarget.dispatchEvent(new Event('change'))
    }

    changeDispatcher() {
        this.dispatch('change', {detail: {value: this.inputTarget.value}})
    }
}