import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['template', 'operations']
  static values = {
    formId: {
      type: String,
      default: 'lending-operations'
    },
    simulateOperationBtnId: {
      type: String,
      default: 'simulate-operation-btn'
    },
    makeOperationBtnId: {
      type: String,
      default: 'make-operation-btn'
    },
    extractOperationBtnId: {
      type: String,
      default: 'extract-operation-btn'
    }
  }

  connect() {
    if (document.getElementsByClassName('lending-operation-container').length === 0) {
      this.add_step()
    }
  }

  add_step() {
    this.operationsTarget.insertAdjacentHTML('beforebegin', this.templateTarget.innerHTML.replace(/NEW_STEP/g, 'operations_step' + new Date().getTime().toString()))
  }

  remove_step(event) {
    document.getElementById(event.params.id).remove()
  }

  simulate_operation(event) {
    const form = document.getElementById(this.formIdValue)
    form.action = event.params.simulateUrl
    Turbo.navigator.submitForm(form)
  }

  make_operation(event) {
    const form = document.getElementById(this.formIdValue)
    form.action = event.params.processUrl
    form.submit();
  }
}
