export default function MiiEmbedRenderer() {
  const embedContainerClass = 'js-embed-container';
  const embedQuery = 'oembed[url]'

  function shouldLoad() {
    return (document.body.querySelectorAll( embedQuery ).length > 0)
  };

  function init() {
    if (shouldLoad()) { load(); }
  };

  function load() {
    document.querySelectorAll( embedQuery ).forEach( element => {
      console.log('loading embed');

      // We create a div to load iframely
      const div = document.createElement('div');
      div.className = embedContainerClass;

      const figure = element.parentNode;
      const parent = figure.parentNode;

      // We insert the div just before embeded figure
      parent.insertBefore(div, figure);

      // We use iframely to load change embed => iframe
      iframely.load(div, element.attributes.url.value );

      // We remove the useless figure : no need to keep it now we have iframe
      figure.remove();
    } );
  };

  function teardown() {
    console.log("teardown MiiEmbedRenderer");
    // Does nothing
  };

  return {
      init: init,
      teardown: teardown
  };

};
