import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["amount", "total", "input"]
    static values = {
        collectId: String,
        multiplier: Number,
        includeCapital: Boolean,
    }

    connect() {
        const _ = this.updateSimulation()
    }

    async updateSimulation(event) {
        const amount = this.inputTarget.value ?? event?.detail?.value
        try {
            const { error, amount: simulatedAmount, interests } = await this.fetchSimulatedAmount(amount)

            if (error) {
                throw new Error(error)
            }
            this.amountTarget.innerHTML = `${simulatedAmount} €`
            this.totalTarget.innerHTML = `${simulatedAmount + interests} €`
        } catch (error) {
            console.error("unable to compute interest", error)
            this.amountTarget.innerHTML = '-'
            this.totalTarget.innerHTML = '-'
        }
    }

    async fetchSimulatedAmount(amount) {
        const url = this.buildSimulatorURL(amount)
        const result = await fetch(url)
        return await result.json()
    }

    buildSimulatorURL(amount) {
        const url = new URL('/fr/interests_simulator', window.location.origin)
        const searchParams = new URLSearchParams(url.search)
        searchParams.set('amount', parseInt(amount, 10) * (this.multiplierValue || 1))
        searchParams.set('collect_id', this.collectIdValue)
        searchParams.set('interests_with_capital', this.includeCapitalValue)
        url.search = searchParams.toString()
        return url.toString()
    }
}