import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "city", "pc"]

    connect() {
        this.initAutocomplete()
    }

    initAutocomplete() {
        if (!window.google || !window.google.maps) {
            console.error('Google Maps JavaScript API library must be loaded.')
            return
        }

        const options = {
            componentRestrictions: { country: ['BE', 'FR'] },
            fields: ['address_components', 'geometry', 'formatted_address'],
            strictBounds: false,
            types: ['address']
        }

        this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, options)
        this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    }

    placeChanged() {
        const place = this.autocomplete.getPlace()

        if (!place.geometry) {
            console.error('Autocomplete’s returned place contains no geometry')
            return
        }

        const addressComponents = place.address_components
        let address = ''
        let city = ''
        let postalCode = ''

        for (const component of addressComponents) {
            const componentType = component.types[0]

            switch (componentType) {
                case 'street_number':
                    address = `${component.long_name} ${address}`
                    break
                case 'route':
                    address += component.long_name
                    break
                case 'locality':
                    city = component.long_name
                    break
                case 'postal_code':
                    postalCode = component.long_name
                    break
                default:
                    break
            }
        }

        this.inputTarget.value = address
        this.cityTarget.value = city
        this.pcTarget.value = postalCode
    }
}